import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Row,
  Spinner,
} from "react-bootstrap";
import styles from "./refferals.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { gameHistory, refferalsListApi } from "../../../lib/store/actions";
import { copyToClipboard, showToast, wait } from "../../../helper/common";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import LoaderCstm from "../../../components/Loader";
import { gameSlice } from "../../../lib/store/game/slice";
import { useNavigate } from "react-router-dom";
import { useWindow } from "../../../hooks/useWindow";
import coin from "../../../assets/images/diamond.png"

const Refferals = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, refferalsList, totalRefferalsList, loading } = useSelector(
    ({ Auth, Game }) => ({
      user: Auth.user,
      loading: Game.loadingRefferalList,
      refferalsList: Game.refferalsList,
      totalRefferalsList: Game.totalRefferalsList,
    })
  );
  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    order: -1,
    orderBy: "date_created_utc",
    search: "",
    level: 1
  });
  const [disableMultipleClicks, setDisableMultipleClicks] = useState(false);

  useEffect(() => {
    return (() => {
      dispatch(gameSlice.actions.clearRefferalsList({}));
    })
  }, [])

  useEffect(() => {
    dispatch(refferalsListApi({ data: filter, userId: user?._id }));
  }, [filter, user]);

  const fetchMore = async () => {
    await wait(1000);
    setFilter((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  const { screenSize: { dynamicHeight } } = useWindow();
  const elementRef = useRef(null); // Create a ref for the element
  const scrollStopOn = (dynamicHeight - 160) > elementRef.current?.offsetHeight ? true : false;

  return (
    <>
      <section className={`${styles.refferals} ${scrollStopOn && totalRefferalsList <= 10 ? "no-minimize" : ""} `}>
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className={`${styles.cardCstm} py-2 cardCstm pt-3`}>
                <div className={`${styles.top} mx-auto top pb-3 text-center position-relative`} style={{maxWidth: 331}}>
                  <Button
                    onClick={() => navigate("/friends")}
                    className="border-0 p-0 position-absolute "
                    variant="transparent"
                    style={{ top: 0, left: 10 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="15"
                      viewBox="0 0 14 20"
                      fill="none"
                    >
                      <path
                        d="M2.91436 9.76907L12.1877 2.01034C12.4158 1.81953 12.4158 1.5135 12.1877 1.32268C11.9596 1.13186 11.5939 1.13186 11.3658 1.32268L1.67935 9.42704C1.45128 9.61785 1.45128 9.92388 1.67935 10.1147L11.3658 18.2155C11.4777 18.3091 11.6283 18.3595 11.7746 18.3595C11.9209 18.3595 12.0715 18.3127 12.1834 18.2155C12.4115 18.0246 12.4115 17.7186 12.1834 17.5278L2.91436 9.76907Z"
                        fill="white"
                        stroke="white"
                        stroke-width="2.26051"
                      />
                    </svg>
                  </Button>
                  <div className="top text-center">
                    <h5 className="m-0 fw-bold" >Refferals</h5>
                  </div>
                </div>
                <div className="d-flex my-1 gap-10 mx-auto justify-content-center pb-2" style={{maxWidth: 331}}>
                  <div
                    className={`${styles.tab} ${filter?.level === 1 ? styles.active : ""} p-1 box text-white px-2 d-flex align-items-center justify-content-center w-50`}
                    onClick={() => setFilter((pre) => ({ ...pre, level: 1 }))}
                  >
                    LEVEL 1
                  </div>
                  <div
                    className={`${styles.tab} ${filter?.level === 2 ? styles.active : ""} p-1 box text-white px-2 d-flex align-items-center justify-content-center w-50`}
                    onClick={() => setFilter((pre) => ({ ...pre, level: 2 }))}
                  >
                    LEVEL 2
                  </div>
                </div>

                {loading && filter.page === 1 && <LoaderCstm />}
                <InfiniteScroll
                  dataLength={refferalsList?.length || 0}
                  next={fetchMore}
                  hasMore={refferalsList?.length < totalRefferalsList}
                  scrollThreshold={0}
                  scrollableTarget="refferalsList"
                  loader={
                    loading &&
                    filter.page >= 2 && (
                      <div className="d-flex justify-content-center align-items-center">
                        <Spinner className={styles.mSpinner} size="sm" />
                      </div>
                    )
                  }
                >
                  <div id="refferalsList" className="table-responsive allow-scroll" style={{ overflowY: "scroll", height: "calc(100vh - 160px)" }}>
                    {/* {!loading && !refferalsList?.length && totalRefferalsList === 0 ?
                      <div className="d-flex justify-content-center align-items-center">
                        <span className='text-white'>No record found!</span>
                      </div>
                      : */}

                    <table ref={elementRef} className="table text-center"
                    >
                      <thead className='no-minimize'>
                        <tr>
                          <th className="border-0 bg-transparent fw-normal">
                            Wallet Id
                          </th>
                          <th className="border-0 bg-transparent fw-normal">
                            Earning
                          </th>
                          {filter?.level === 2 ?
                            <th className="border-0 bg-transparent fw-normal">
                             Level 1 Wallet
                            </th>
                            : null}
                          <th className="border-0 bg-transparent fw-normal">
                            Date
                          </th>
                          {/* <th className="border-0 bg-transparent fw-normal"></th> */}
                        </tr>
                      </thead>
                      <tbody

                      >

                        {refferalsList?.map((item, index) => (
                          <tr key={"history" + index} >
                            {/* <td className="border-0 bg-transparent px-2 py-3">
                              <h6 className="cursor-pointer m-0 fw-normal text-white">
                                {item?.userID}{" "}

                              </h6>
                            </td> */}
                            <td className="border-0 bg-transparent px-2 py-3">
                              <h6 className="cursor-pointer m-0 fw-normal" style={{color: "#01F4C3"}}>
                                {item?.invitedUserDetails?.username}{" "}
                              </h6>
                            </td>
                            <td className="border-0 bg-transparent px-2 py-3">
                              <h6 className="cursor-pointer m-0 fw-normal text-white d-flex align-items-center">
                                <img src={coin} alt="" className="img-fluid me-1 object-fit-contain" style={{height: 16}} />
                                {item?.earning}{" "}
                              </h6>
                            </td>
                            {filter?.level === 2 ?
                              <td className="border-0 bg-transparent px-2 py-3">
                                <h6 className="cursor-pointer m-0 fw-bold" style={{color: "#909096"}}>
                                  {item?.parentUserDetails?.username}{" "}
                                </h6>
                              </td>
                              : null}
                            <td className="border-0 bg-transparent px-2 py-3">
                              <h6 className="m-0 fw-bold" style={{color: "#909096"}}>

                              {item?.invitedUserDetails?.createdAt &&
                                moment(item?.invitedUserDetails?.createdAt).format(
                                  "DD.MM.YY"
                                )} <br />
                                {item?.invitedUserDetails?.createdAt &&
                                moment(item?.invitedUserDetails?.createdAt).format(
                                  "HH:mm:ss"
                                )}
                              </h6>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/* } */}
                  </div>
                </InfiniteScroll>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Refferals;
