import Login from "./AuthPages/Login";
import Game from "./MainPages/Game";
import GameHistory from "./MainPages/GameHistory";
import LeaderBoard from "./MainPages/Leaderboard";
import Roadmap from "./MainPages/RoadMap";
import TaskList from "./MainPages/TaskList";
import Wallet from "./MainPages/wallet";
import Friends from "./MainPages/Friends";
import Refferals from "./MainPages/Refferals";
import EarningsHistory from "./MainPages/EarningsHistory";

export const routes = [
  { path: "/login", component: <Login /> },
];

export const privateRoutes = [
  { path: "/game", component: <Game /> },
  { path: "/task", component: <TaskList /> },
  { path: "/wallet", component: <Wallet /> },
  { path: "/game-history", component: <GameHistory /> },
  { path: "/leaderboard", component: <LeaderBoard /> },
  { path: "/roadmap", component: <Roadmap /> },
  { path: "/friends", component: <Friends /> },
  { path: "/refferals", component: <Refferals /> },
  { path: "/earnings", component: <EarningsHistory /> }
];
