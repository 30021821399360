/* Instruments */
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as URL from "../../../helper/url_helper";
import { get, post, put, updateToken } from "../../../helper/api_helper";
import { gameSlice } from "./slice";
import { generateSignature, showToast } from "../../../helper/common";
import { authSlice } from "../auth/slice";
import { socket } from "../../../utils/socket";
import { getValuesSocketData } from "../../../helper/secretManager";

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
let abortController;
export const gameStart = createAsyncThunk(
  "gameStart",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();
      if (abortController) {
        abortController.abort(); // Abort previous request if any
      }
      abortController = new AbortController(); // Create a new controller
      const response = await post(URL.GAME_START, data, { signal: abortController.signal, signature, nonce });
      if (response?.status_code === 405) {

        showToast(response?.message, "error");
        callBack && callBack(response?.message, null);
        Thunk.dispatch(authSlice.actions.clearUserDetails())
        document.cookie = "";
        window.location.reload();
        return Thunk.rejectWithValue(response?.message);

      }
      if (response?.status === "failure") {
        showToast(response?.message, "error");
        callBack && callBack(response?.message, null);
        return Thunk.rejectWithValue(response?.message);
      }

      callBack && callBack(null, response);
      return response;
    } catch (error) {
      console.log(error, "<===error");
      if (error.name === 'AbortError' || error?.message === "canceled" || error === "canceled") {
        callBack && callBack({ message: "canceled" });
        return Thunk.rejectWithValue('Request cancelled');
      } else {
        showToast(error?.message || "Something went wrong!", "error");
        callBack && callBack(error);
        return Thunk.rejectWithValue(error);
      }
    }
  }
);

export const gameComplete = createAsyncThunk(
  "gameComplete",
  async ({ data, callBack }, Thunk) => {
    try {
      if (abortController) {
        abortController.abort(); // Abort previous request if any
      }
      abortController = new AbortController(); // Create a new controller
      const { signature, nonce } = await getValuesSocketData();
      const response = await put(URL.GAME_COMPLETE + data?._id, data, { signal: abortController.signal, signature, nonce });
      if (response?.status_code === 405) {

        showToast(response?.message, "error");
        callBack && callBack(response?.message, null);
        Thunk.dispatch(authSlice.actions.clearUserDetails())
        document.cookie = "";
        window.location.reload();
        return Thunk.rejectWithValue(response);

      }
      if (response?.status_code === 406) {
        callBack && callBack(response?.message, null);
        return Thunk.rejectWithValue(response);
      }
      if (response?.status === "failure") {
        callBack && callBack(response || true, null);
        showToast(response?.message, "error");
        return Thunk.rejectWithValue(response);
      }
      callBack && callBack(null, response);
      Thunk.dispatch(authSlice.actions.setUserDetails({ user: response?.data }))
      showToast(response?.message, "success");
      return response;
    } catch (error) {
      if (error.name === 'AbortError' || error?.message === "canceled" || error === "canceled") {
        callBack && callBack({ message: "canceled" });
        return Thunk.rejectWithValue('Request cancelled');
      } else {
        showToast(error?.message || "Something went wrong!", "error");
        callBack && callBack(error);
        return Thunk.rejectWithValue(error);
      }
    }
  }
);


export const updateGameApi = createAsyncThunk(
  "updateGameApi",
  async ({ data, callBack }, Thunk) => {
    try {
      if (abortController) {
        abortController.abort(); // Abort previous request if any
      }
      abortController = new AbortController(); // Create a new controller
      const { signature, nonce } = await getValuesSocketData();
      const response = await put(URL.GAME_UPDATE + data?._id, data, { signal: abortController.signal, signature, nonce });
      if (response?.status_code === 405) {

        showToast(response?.message, "error");
        callBack && callBack(response?.message, null);
        Thunk.dispatch(authSlice.actions.clearUserDetails())
        document.cookie = "";
        window.location.reload();
        return Thunk.rejectWithValue(response?.message);

      }
      if (response?.status_code === 406) {
        callBack && callBack(response?.message, null);
        return Thunk.rejectWithValue(response?.message);
      }
      if (response?.status === "failure") {
        callBack && callBack(response);
        return Thunk.rejectWithValue(response);
      }
      callBack && callBack(null, response);
      return response;
    } catch (error) {
      console.log(error, "<===error");
      if (error.name === 'AbortError' || error?.message === "canceled" || error === "canceled") {
        callBack && callBack({ message: "canceled" });
        return Thunk.rejectWithValue('Request cancelled');
      } else {
        // showToast(error?.message || "Something went wrong!", "error");
        callBack && callBack(error);
        return Thunk.rejectWithValue(error);
      }

    }
  }
);


export const activeGameDetail = createAsyncThunk(
  "activeGameDetail",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData(true);
      const response = await get(URL.GAME_DETAILS + data?._id, { signature, nonce });
      if (response?.status_code === 405) {

        showToast(response?.message, "error");
        callBack && callBack(response?.message, null);
        Thunk.dispatch(authSlice.actions.clearUserDetails())
        document.cookie = "";
        window.location.reload();
        return Thunk.rejectWithValue(response?.message);

      }
      if (response?.status === "failure") {
        showToast(response?.message, "error");
        callBack && callBack(response?.message, null);
        return Thunk.rejectWithValue(response?.message);
      }

      callBack && callBack(null, response);
      return response;
    } catch (error) {
      console.log(error, "<===error");
      showToast(error?.message || "Something went wrong!", "error");
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);


export const buyEnergy = createAsyncThunk(
  "buyEnergy",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();
      const response = await post(URL.PURCHASE_ENERGY, data, { signature, nonce });
      if (response?.status_code === 405) {

        showToast(response?.message, "error");
        callBack && callBack(response?.message, null);
        Thunk.dispatch(authSlice.actions.clearUserDetails())
        document.cookie = "";
        window.location.reload();
        return Thunk.rejectWithValue(response?.message);

      }
      if (response?.status === "failure") {
        showToast(response?.message, "error");
        callBack && callBack(response?.message, null);
        return Thunk.rejectWithValue(response?.message);
      }
      showToast(response?.message, "success");
      callBack && callBack(null, response);
      return response;
    } catch (error) {
      console.log(error, "<===error");
      showToast(error?.message || "Something went wrong!", "error");
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);


export const gameHistory = createAsyncThunk(
  "gameHistory",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();
      const response = await post(URL.GAME_HISTORY_LIST, data, { signature, nonce });
      if (response?.status_code === 405) {

        showToast(response?.message, "error");
        callBack && callBack(response?.message, null);
        Thunk.dispatch(authSlice.actions.clearUserDetails())
        document.cookie = "";
        window.location.reload();
        return Thunk.rejectWithValue(response?.message);

      }
      callBack && callBack(null, response);
      response.filter = data;
      return response;
    } catch (error) {
      console.log(error, "<===error");
      showToast(error?.message || "Something went wrong!", "error");
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);


export const gameLeaderBoard = createAsyncThunk(
  "gameLeaderBoard",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();
      const response = await post(URL.GAME_LEADERBOARD_LIST, data, { signature, nonce });
      if (response?.status_code === 405) {

        showToast(response?.message, "error");
        callBack && callBack(response?.message, null);
        Thunk.dispatch(authSlice.actions.clearUserDetails())
        document.cookie = "";
        window.location.reload();
        return Thunk.rejectWithValue(response?.message);

      }
      callBack && callBack(null, response);
      return response;
    } catch (error) {
      console.log(error, "<===error");
      showToast(error?.message || "Something went wrong!", "error");
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);



export const gameClaimHistoryApi = createAsyncThunk(
  "gameClaimHistoryApi",
  async ({ data, callBack }, Thunk) => {
    try {
      if (data?.page === 1) {
        Thunk.dispatch(gameSlice.actions.clearClaimHistoryList())
      }
      const { signature, nonce } = await getValuesSocketData();
      const response = await post(URL.CLAIM_LIST, data, { signature, nonce });
      if (response?.status_code === 405) {

        showToast(response?.message, "error");
        callBack && callBack(response?.message, null);
        Thunk.dispatch(authSlice.actions.clearUserDetails())
        document.cookie = "";
        window.location.reload();
        return Thunk.rejectWithValue(response?.message);

      }
      callBack && callBack(null, response);
      response.filter = data;

      return response;
    } catch (error) {
      console.log(error, "<===error");
      showToast(error?.message || "Something went wrong!", "error");
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);



export const claimBalanceApi = createAsyncThunk(
  "claimBalanceApi",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      const response = await put(URL.CLAIM_BALANCE + data?._id, {}, { signature, nonce });
      if (response?.status_code === 405) {

        showToast(response?.message, "error");
        callBack && callBack(response?.message, null);
        Thunk.dispatch(authSlice.actions.clearUserDetails())
        document.cookie = "";
        window.location.reload();
        return Thunk.rejectWithValue(response?.message);

      }
      if (response?.status === "failure") {
        showToast(response?.message, "error");
        callBack && callBack(response?.message, null);
        return Thunk.rejectWithValue(response?.message);
      }
      showToast(response?.message, "success");
      callBack && callBack(null, response);
      return response;
    } catch (error) {
      console.log(error, "<===error");
      showToast(error?.message || "Something went wrong!", "error");
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);



export const getBitanicaBtncBalanceApi = createAsyncThunk(
  "getBitanicaBtncBalanceApi",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();
      const response = await get(URL.GET_BTANICA_BALANCE, { signature, nonce });
      if (response?.status_code === 405) {

        showToast(response?.message, "error");
        callBack && callBack(response?.message, null);
        Thunk.dispatch(authSlice.actions.clearUserDetails())
        document.cookie = "";
        window.location.reload();
        return Thunk.rejectWithValue(response?.message);

      }
      if (response?.status === "failure") {
        //        showToast(response?.message, "error");
        callBack && callBack(response?.message, null);
        return Thunk.rejectWithValue(response?.message);
      }

      callBack && callBack(null, response?.data);
      return response;
    } catch (error) {
      console.log(error, "<===error");
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);


export const transferFromBitanicaApi = createAsyncThunk(
  "transferFromBitanicaApi",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();
      const response = await post(URL.TRANSFER_BTNC_FROM_BITANICA, data, { signature, nonce });
      if (response?.status_code === 405) {

        showToast(response?.message, "error");
        callBack && callBack(response?.message, null);
        Thunk.dispatch(authSlice.actions.clearUserDetails())
        document.cookie = "";
        window.location.reload();
        return Thunk.rejectWithValue(response?.message);

      }
      if (response?.status === "failure") {
        showToast(response?.message, "error");

        callBack && callBack(response?.message, null);
        return Thunk.rejectWithValue(response?.message);
      }
      showToast(response?.message, "success");
      callBack && callBack(null, response);
      return response;
    } catch (error) {
      console.log(error, "<===error");
      showToast(error?.message || "Something went wrong!", "error");
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const refferalsListApi = createAsyncThunk(
  "refferalsListApi",
  async ({ data, callBack }, Thunk) => {
    try {
      if (data?.page === 1) {
        Thunk.dispatch(gameSlice.actions.clearRefferalsList())
      }
      const { signature, nonce } = await getValuesSocketData();
      const response = await post(URL.USER_REFFERALS_LIST, data, { signature, nonce });
      if (response?.status_code === 405) {

        showToast(response?.message, "error");
        callBack && callBack(response?.message, null);
        Thunk.dispatch(authSlice.actions.clearUserDetails())
        document.cookie = "";
        window.location.reload();
        return Thunk.rejectWithValue(response?.message);

      }
      callBack && callBack(null, response);
      response.filter = data;

      return response;
    } catch (error) {
      console.log(error, "<===error");
      showToast(error?.message || "Something went wrong!", "error");
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);


export const earningsListApi = createAsyncThunk(
  "earningsListApi",
  async ({ data, callBack }, Thunk) => {
    try {
      if (data?.page === 1) {
        Thunk.dispatch(gameSlice.actions.clearRefferalsList())
      }
      const { signature, nonce } = await getValuesSocketData();
      const response = await post(URL.USER_EARNINGS_LIST, data, { signature, nonce });
      if (response?.status_code === 405) {

        showToast(response?.message, "error");
        callBack && callBack(response?.message, null);
        Thunk.dispatch(authSlice.actions.clearUserDetails())
        document.cookie = "";
        window.location.reload();
        return Thunk.rejectWithValue(response?.message);

      }
      callBack && callBack(null, response);
      response.filter = data;

      return response;
    } catch (error) {
      console.log(error, "<===error");
      showToast(error?.message || "Something went wrong!", "error");
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);
