import React from "react";
import { Container } from "react-bootstrap";
// css
import styles from "./Maintenance.module.scss";
import i2 from "../../assets/images/maintaince.png";
const Maintenance = () => {
  return (
    <>
      <section
        className={`${styles.page} text-center position-relative d-flex align-items-center justify-content-center`}
      >
        <img
          src={i2}
          alt=""
          className="img-fluid w-100 object-fit-cover mx-auto h-full"
          style={{ minHeight: "100vh", maxWidth: 430 }}
        />
      </section>
    </>
  );
  
};

export default Maintenance;
