import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Nav, Row, Tab } from "react-bootstrap";
import styles from "./wallet.module.scss";

// img
import btnc from "../../../assets/images/diamond.png";
import LinkAccountPop from "../../../components/Modals/LinkAccountPop";
import BtncHistory from "./Component/Btnc";
import CryptoHistory from "./Component/Crypto";
import { useDispatch, useSelector } from "react-redux";
import { get } from "../../../helper/api_helper";
import { CHECK_BITANICA_LINKING } from "../../../helper/url_helper";
import { checkBitanicaLinkApi } from "../../../lib/store/actions";
// import useUserBalance from "../../../hooks/useUserBalance";
import { useGameContext } from "../../../context/GameContext";
import { copyToClipboard, showToast } from "../../../helper/common";

const Wallet = () => {
  const { isBitanicaLinked } = useSelector(({ Auth }) => Auth);

  const dispatch = useDispatch();
  const { gameEnergyLeft, btncBalance } = useGameContext();
  const [activeTab, setActiveTab] = useState("BTNC");
  const [linkAccount, setLinkAccount] = useState();
  const handleLinkAccount = () => {
    setLinkAccount(!linkAccount);
  };
  const { user } = useSelector(({ Auth }) => ({
    user: Auth.user,
  }));

  const [disableMultipleClicks, setDisableMultipleClicks] = useState(false);
  // useEffect(() => {
  //   dispatch(checkBitanicaLinkApi({}));
  // }, []);
  const onCopy = async (value) => {
    try {
      if (disableMultipleClicks) return; //stop continuous clicks

      setDisableMultipleClicks(true);

      setTimeout(() => {
        // disableMultipleClicks = false;
        setDisableMultipleClicks(false);
      }, 3000);
      const message = await copyToClipboard(value);
      // addToast(message, 'success', 3000);
      showToast(message, "success")
    } catch (err) {
      // addToast(err, 'error', 3000);
      showToast(typeof err === "string" ? err : err?.message, "error")
    }

  }
  return (
    <>
      {linkAccount &&
        <LinkAccountPop
          linkAccount={linkAccount}
          setLinkAccount={setLinkAccount}
        />
      }
      <section className={`${styles.walletSec} wallet position-relative`}>
        <Container>
          <Row>
            <Col lg="12" className="my-2 no-minimize">
              <div className="top text-center">
                <h5 className="m-0 themeClr fw-bold">Balance</h5>
                <div className="pt-2 d-flex align-items-center justify-content-center">
                  <img
                    src={btnc}
                    alt=""
                    className="img-fluid object-fit-contain flex-shrink-0 me-1"
                    style={{ height: 25 }}
                  />
                  <h2
                    className="m-0 fw-bold text-white"
                    style={{ fontSize: 19 }}
                  >
                    {btncBalance || 0}  <span className="mx-2 themeClr fw-bold">
                      BTNC
                    </span>
                  </h2>
                </div>
              </div>
            </Col>
            <Col lg="12" className="mb-2">
              <hr style={{ maxWidth: 211 }} className="mx-auto border-light mt-0" />
              <Form className={`${styles.walletForm} mx-auto`} >
                <p className="m-0 text-center fw-bold">
                  Wallet ID
                </p>
                <div className="mx-auto"
                  style={{ maxWidth: 295 }}>
                  <div
                    className="input d-flex align-items-center justify-content-between my-1 mb-2 px-3 p-2 rounded-2"
                    style={{ background: "#1B1B26", height: 48 }}
                  >
                    <p className="m-0 ">{user?.username || "Jatinder Singh"}</p>
                    <Button
                      onClick={() => onCopy(user?.username || "Jatinder Singh")}
                      className="border-0 p-0"
                      variant="transparent"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 19 22"
                        fill="none"
                      >
                        <path
                          opacity="0.5"
                          d="M14 22H2C0.895 22 0 21.105 0 20V6H2V20H14V22ZM17 18H6C4.895 18 4 17.105 4 16V2C4 0.894999 4.895 -4.76837e-07 6 -4.76837e-07H17C18.105 -4.76837e-07 19 0.894999 19 2V16C19 17.105 18.105 18 17 18ZM17 2H6V16H17V2Z"
                          fill="white"
                        />
                      </svg>
                    </Button>
                  </div>
                  <Button
                    disabled={isBitanicaLinked}
                    style={{ height: 50 }}
                    onClick={() => window.open("https://bitanica.com/registration", "blank")}
                    className={`${styles.submit} d-inline-flex align-items-center w-100 fw-bold justify-content-center commonBtn border-0 my-1`}
                  >
                    {isBitanicaLinked ? "Connected" : "Link to Bitanica"}

                  </Button>
                </div>
                <p className="m-0 fw-normal pt-2 text-center" style={{
                  fontFamily: "Space Grotesk, sans-serif",
                  fontSize: 14
                }}>Use this code to link your account to <span style={{ fontWeight: 500 }}>Bitanica.com</span></p>
              </Form>
            </Col>
            <Col lg="12" className="my-2">
              <CryptoHistory />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Wallet;
