import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// css
import styles from "./Authe.module.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { telegramBotAutoLogin, telegramLogin } from "../../../lib/store/auth/actions";
import useSocket from "../../../hooks/useSocket";
import useReverseTimer from "../../../hooks/useReverseTimer";
import { useGameContext } from "../../../context/GameContext";
import LoaderCstm from "../../../components/Loader";
import { decrypt } from "../../../helper/secretManager";
import { authSlice } from "../../../lib/store/auth/slice";
// import { LoginButton } from '@telegram-auth/react';


const Login = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { telMobLoading, qrLoginLoader } = useSelector(({ Auth }) => Auth);
  const { settings } = useSelector(({ Settings }) => Settings);

  const dispatch = useDispatch();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [qrImage, setQrImage] = useState(null);
  const { socket, socketConnect } = useSocket();
  const [loader, setLoader] = useState(false);
  const { setGame, setGameStart, setGameLoading } = useGameContext();



  const ativeGameDetail = (userData) => {
    return new Promise(async (resolve, reject) => {
      try {

        if ((userData?._id && !userData?.currentGame?._id) || !userData?._id || !userData?.currentGame?._id) {
          setTimeout(() => {
            setLoader(false);
            setGameLoading(false);
            resolve();  // Resolve since no game to load
          }, 2000);
          return;
        }

        setLoader(true);
        let updatedData = { ...userData?.currentGame, gameData: [], lineData: [] };

        // const keys = await socket.emit('getValue', { data: {} });

        // let decoded = updatedData?.gameData
        //   ? await decrypt(userData?.currentGame?.gameData, keys?.ENCRYPT_SECRET_KEY, keys?.ENCRYPT_SECRET_IV)
        //   : [];
        // decoded = decoded ? JSON.parse(decoded) : [];

        updatedData = {
          ...updatedData,
          gameData: userData?.currentGame?.gameData,
          lineData: userData?.currentGame?.lineData,
          winnings: updatedData?.winnings?.length
            ? [...updatedData.winnings]
            : [],
        };

        setGame({
          ...updatedData,
          gameData: updatedData?.gameData.reverse(),
          lineData: updatedData?.lineData.reverse(),
        });

        setTimeout(() => {
          setLoader(false);
          setGameLoading(false);
          resolve(updatedData);  // Resolve with the updated data
          setGameStart(true);
        }, 1000);

      } catch (err) {
        setLoader(false);
        setGameLoading(false);
        reject(err);  // Reject if there is an exception
      }
    });
  };

  useEffect(() => {
    setGameLoading(false);
    const telegramUser = window.Telegram.WebApp.initDataUnsafe.user;
    if (socket && socketConnect && !telegramUser) {
      // Listen to 'message' event
      socket.emit('qrCode', { data: {} }, (data) => {
        setQrImage(data?.qrCode);
        startTimer(parseInt(data?.expTime))
      });

      socket.on('login', (data) => {

        if (data?.status === "success") {
          telegramWebAppLogin(data);
        }
      });
    }

    // Clean up the event listener when component unmounts
    return () => {
      if (socket) {
        socket.off('login');
      }
    };
  }, [socket, socketConnect]);


  const telegramWebAppLogin = async (data) => {
    try {
      setLoader(true); // Show loader while trying to log in
      dispatch(authSlice.actions.setLoginBotQrLoader({ type: "qrLogin" }));
      dispatch(telegramBotAutoLogin({
        data: {
          telegram_id: data.telegram_id,
          telegram_username: data?.telegram_username,
          first_name: data?.first_name,
          last_name: data?.last_name
        },
        callBack: async (err, resData) => {
          if (parseInt(err?.status_code) === 406) {
            setLoader(false);
            return navigate("/maintenance");
          }
          if (err) {
            // Increment retry count if login fails and retry
            setLoader(false);
            return;
          }

          await ativeGameDetail(resData);
          setTimeout(() => {
            setLoader(false);
            navigate("/game");
          }, 3000)
        }
      }));
    } catch (err) {
      console.error("Error during Telegram WebApp login", err);
      setLoader(false);
    }
  };

  // useEffect(() => {
  //   // Get the hash part of the URL
  //   const hash = window.location.hash;
  //   console.log("hashhashhashhashhash", hash)

  //   // Extract the tgAuthResult if it's present in the hash
  //   const match = hash.match(/tgAuthResult=([^&]*)/);
  //   console.log("matchmatchmatch", match)
  //   if (match && match[1]) {
  //     // URL decode the value (optional, depending on the encoding)
  //     const token = decodeURIComponent(match[1]);

  //     const payload = {
  //       token: token,
  //     };
  //     console.log("payloadpayload", payload)
  //     if (payload.token) {
  //       setLoader(true);
  //       dispatch(telegramLogin({
  //         data: payload, callBack: async (err, data) => {
  //           if (parseInt(err?.status_code) === 406) {
  //             return navigate("/maintenance");
  //           }
  //           if (err) {
  //             setTimeout(() => {
  //               setLoader(false);
  //             }, 1000);
  //             return;
  //           }
  //           if (data) {
  //             await ativeGameDetail(data);
  //             setTimeout(() => {
  //               setLoader(false);
  //               navigate("/game")
  //             }, 3000);
  //           }
  //         }
  //       }))
  //     }

  //   }

  // }, []);

  // const getQrCode = async () => {
  //   try {
  //     dispatch(generateTelLoginQrApi({
  //       callBack: (err, data) => {
  //         if (err) return;
  //         setQrImage(data);
  //       }
  //     }));
  //   } catch (err) {

  //   }
  // }

  // useEffect(() => {
  //   getQrCode();
  // }, []);

  const generateQrAgain = () => {
    if (socket && socketConnect) {
      // Listen to 'message' event
      socket.emit('qrCode', { data: {} }, (data) => {
        setQrImage(data?.qrCode);
        startTimer(parseInt(data?.expTime))
      });
    }
  }

  const onClickLoginTel = () => {
    const currentUrl = window.location.href;
    let link;
    if (currentUrl.includes("staging") || currentUrl.includes("localhost")) {
      link = `https://oauth.telegram.org/auth?bot_id=8112272858&origin=https%3A%2F%2Fstaging.bitanicagames.com&embed=1&request_access=write&lang=en&return_to=https%3A%2F%2Fstaging.bitanicagames.com%2Flogin/?v=${Date.now()}`;
    } else {
      link = `https://oauth.telegram.org/auth?bot_id=7908178539&origin=https%3A%2F%2Fwww.bitanicagames.com&embed=1&request_access=write&lang=en&return_to=https%3A%2F%2Fwww.bitanicagames.com%2Flogin/?v=${Date.now()}`;
    }


    window.open(link, "_self")
  }
  const { timeLeft, startTimer } = useReverseTimer(generateQrAgain);
  return (
    <>
      <section
        className={`${styles.authentication} authentication text-center py-5 position-relative d-flex align-items-center justify-content-center`}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg="5" md="6" sm="10">
              {(loader || telMobLoading || qrLoginLoader) && <LoaderCstm />}
              <div className={`${styles.authFormWrpper} p-4`}>
                <div className="formBody">
                  <div className="icon position-relative mx-auto mb-3 bg-white rounded d-flex align-items-center justify-content-center"
                    style={{ height: 150, width: 150 }}>
                    {qrImage ?
                      <>
                        <img src={qrImage} alt="" className="img-fluid w-100 h-100 rounded" />
                        <span className="icn mx-auto position-absolute"
                          style={{ top: "50%", left: 0, right: 0, transform: "translateY(-50%)" }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 256 256" fill="none"><g clip-path="url(#clip0_39_650)"><path d="M128 0C94.06 0 61.48 13.494 37.5 37.49C13.4954 61.4956 0.00686897 94.0517 0 128C0 161.934 13.5 194.514 37.5 218.51C61.48 242.506 94.06 256 128 256C161.94 256 194.52 242.506 218.5 218.51C242.5 194.514 256 161.934 256 128C256 94.066 242.5 61.486 218.5 37.49C194.52 13.494 161.94 0 128 0Z" fill="url(#paint0_linear_39_650)"></path><path d="M57.94 126.648C95.26 110.392 120.14 99.6746 132.58 94.496C168.14 79.71 175.52 77.142 180.34 77.055C181.4 77.038 183.76 77.3 185.3 78.545C186.58 79.595 186.94 81.015 187.12 82.012C187.28 83.008 187.5 85.278 187.32 87.05C185.4 107.29 177.06 156.406 172.82 179.076C171.04 188.668 167.5 191.884 164.08 192.198C156.64 192.882 151 187.286 143.8 182.568C132.54 175.182 126.18 170.586 115.24 163.38C102.6 155.052 110.8 150.474 118 142.994C119.88 141.036 152.64 111.246 153.26 108.544C153.34 108.206 153.42 106.946 152.66 106.282C151.92 105.616 150.82 105.844 150.02 106.024C148.88 106.28 130.9 118.176 96.02 141.71C90.92 145.218 86.3 146.928 82.14 146.838C77.58 146.74 68.78 144.254 62.24 142.13C54.24 139.524 47.86 138.146 48.42 133.72C48.7 131.416 51.88 129.058 57.94 126.648Z" fill="white"></path></g><defs><linearGradient id="paint0_linear_39_650" x1="12800" y1="0" x2="12800" y2="25600" gradientUnits="userSpaceOnUse"><stop stop-color="#2AABEE"></stop><stop offset="1" stop-color="#229ED9"></stop></linearGradient><clipPath id="clip0_39_650"><rect width="256" height="256" fill="white"></rect></clipPath></defs></svg>
                        </span>
                      </>
                      :
                      <p className="m-0">Loading...</p>
                    }
                  </div>

                  {/* <h4 className="m-0 text-white py-1">Login With Telegram</h4> */}
                  <p className="m-0 text-white py-1">
                    <span>Time Left : </span> {"00 : " + timeLeft}
                  </p>

                  <>
                    {/* <LoginButton
                      botUsername={"BitanicaMinesBot"}
                      authCallbackUrl={"https://www.bitanicagames.com/login"}
                      buttonSize="large" // "large" | "medium" | "small"
                      cornerRadius={5} // 0 - 20
                      showAvatar={true} // true | false
                      lang="en"
                    /> */}
                    <div className="content">
                      <h6 className="m-0 fw-sbold text-center text-white">Log in to Telegram by QR Code</h6>
                      <ul className="list-unstyled ps-0 mb-0 text-start" style={{ fontSize: 14 }}>
                        <li className="py-1 d-flex align-items-start text-white gap-10">
                          <span className="icn d-flex text-white align-items-center flex-shrink-0 justify-content-center rounded-pill"
                            style={{ height: 20, width: 20, fontSize: 10, background: "#2aabed" }}>1</span>
                          Open Telegram on your phone
                        </li>
                        <li className="py-1 d-flex align-items-start text-white gap-10">
                          <span className="icn d-flex text-white align-items-center flex-shrink-0 justify-content-center rounded-pill"
                            style={{ height: 20, width: 20, fontSize: 10, background: "#2aabed" }}>2</span>
                          Go to Settings > Devices > Link Desktop Device
                        </li>

                        <li className="py-1 d-flex align-items-start text-white gap-10">
                          <span className="icn d-flex text-white align-items-center flex-shrink-0 justify-content-center rounded-pill"
                            style={{ height: 20, width: 20, fontSize: 10, background: "#2aabed" }}>3</span>
                          Point your phone at this screen to confirm login
                        </li>
                      </ul>
                    </div>
                    <div className="">

                      <Button
                        style={{ maxWidth: 250 }}
                        className={`${styles.telegramBtn} mx-auto rounded-pill w-100 d-flex px-4 align-items-center justify-content-center  mt-2`} onClick={() =>
                          onClickLoginTel()
                        }> <span className="icn me-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M19.777 4.43C20.0241 4.32599 20.2946 4.29012 20.5603 4.32612C20.826 4.36212 21.0771 4.46867 21.2877 4.63469C21.4982 4.80071 21.6604 5.02012 21.7574 5.27009C21.8543 5.52005 21.8825 5.79144 21.839 6.056L19.571 19.813C19.351 21.14 17.895 21.901 16.678 21.24C15.66 20.687 14.148 19.835 12.788 18.946C12.108 18.501 10.025 17.076 10.281 16.062C10.501 15.195 14.001 11.937 16.001 10C16.786 9.239 16.428 8.8 15.501 9.5C13.199 11.238 9.50299 13.881 8.28099 14.625C7.20299 15.281 6.64099 15.393 5.96899 15.281C4.74299 15.077 3.60599 14.761 2.67799 14.376C1.42399 13.856 1.48499 12.132 2.67699 11.63L19.777 4.43Z" fill="#fff" />
                        </svg></span> Login With Phone</Button>
                    </div>

                  </>

                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section >
    </>
  );
};

export default Login;
