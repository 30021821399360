import { useState, useEffect } from 'react';
import moment from 'moment-timezone';

const useLbCountdown = (onTimerEnd = () => { }) => {
    const [timeLeft, setTimeLeft] = useState({ hours: '00', minutes: '00', seconds: '00' });

    // Helper function to get the next Sunday at 11:59 PM in GMT+4
    const getResetTime = () => {
        const now = moment(); // Get the current moment
        const daysUntilNextSunday = (7 - now.day()) % 7; // Days until next Sunday
        const nextSunday = now.add(daysUntilNextSunday, 'days').endOf('day'); // Move to next Sunday at 11:59 PM
        const nextSundayGMT4 = nextSunday.tz('GMT+4'); // Convert to GMT+4
        return nextSundayGMT4.toDate(); // Return as JavaScript Date object
    };

    // Helper function to get current time in GMT+4
    const getGMT4Time = () => {
        const gmt4Time = moment.tz('GMT+4'); // Convert current time to GMT+4
        return gmt4Time.toDate(); // Return as JavaScript Date object
    };

    useEffect(() => {
        let resetTime = getResetTime(); // Get the initial reset time

        const calculateTimeLeft = () => {
            const now = getGMT4Time(); // Get current time in GMT+4
            const durationInMilliseconds = resetTime - now; // Calculate the time difference

            if (durationInMilliseconds > 0) {
                const hours = String(Math.floor(durationInMilliseconds / (1000 * 60 * 60))).padStart(2, '0'); // Hours
                const minutes = String(Math.floor((durationInMilliseconds / (1000 * 60)) % 60)).padStart(2, '0'); // Minutes
                const seconds = String(Math.floor((durationInMilliseconds / 1000) % 60)).padStart(2, '0'); // Seconds
                setTimeLeft({ hours, minutes, seconds }); // Update the time left
            } else {
                setTimeLeft({ hours: '00', minutes: '00', seconds: '00' }); // Reset to zero
                if (onTimerEnd) {
                    onTimerEnd(); // Trigger the callback
                }
                resetTime = getResetTime(); // Recalculate the reset time to restart the countdown
            }
        };

        calculateTimeLeft(); // Initial calculation on mount

        const timer = setInterval(calculateTimeLeft, 1000); // Update every second

        return () => clearInterval(timer); // Cleanup on component unmount
    }, []); // Empty dependency array ensures it runs once on mount

    return timeLeft; // Return the time left (hours, minutes, seconds)
};

export default useLbCountdown;
