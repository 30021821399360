import React, { useState } from "react";
import { Button, Col, Container, Dropdown, Form, Row } from "react-bootstrap";
import Switch from "react-switch";
import { getUserProfile, updateProfile, userSessionLogout } from "../../lib/store/actions";
// css
import styles from "./Header.module.scss";

// img
import current from "../../assets/images/current.png";
import diamond from "../../assets/images/diamond.png";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import HowToPlayPop from "../Modals/HowToPlay";
import BuyPop from "../Modals/BuyPop";
// import useUserBalance from "../../hooks/useUserBalance";
// import BtncBalancePop from "../Modals/BtncBalancePop";
import { useNavigate } from "react-router-dom"
import { isMobile, showToast } from "../../helper/common";
import { useGameContext } from "../../context/GameContext";
// import { useToast } from "../../context/CustomToast";

const Header = () => {
  // const { addToast } = useToast();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { gameEnergyLeft, setGameEnergyLeft, btncBalance, setBtncBalance } =
    useGameContext();
  const { user: userData } = useSelector(({ Auth }) => Auth);
  const { settings } = useSelector(({ Settings }) => Settings);
  const [buy, setBuy] = useState();
  const [playInst, setPlayInst] = useState();
  // const [checked, setChecked] = useState(false);
  // const [btncPop, setBtncPop] = useState(false);

  const manageSound = (e) => {
    var parms = {
      enabledSound: !userData?.enabledSound,
    };
    // setIsLoading(true);
    dispatch(
      updateProfile({
        data: { ...parms, _id: userData?._id },
        callBack: (err, data) => {
          // setIsLoading(false);
          if (err) {
            return;
          }
          if (data?.enabledSound) {
            // addToast('Sound Enable!!', 'success', 3000);
            showToast("Sound Enabled.", "success");
          } else {
            // addToast('Sound Disable!!', 'success', 3000);
            showToast("Sound Disabled.", "success");
          }
        },
      })
    );
  };
  const handleToggle = (isOpen) => {
    setDropdownOpen(!dropdownOpen);
  };

  const handledBuy = () => {
    setBuy(!buy);
  };
  const handlePlayInst = () => {
    setPlayInst(!playInst);
  };



  const getProfile = () => {
    if (!userData?._id) return;
    let bodyParms = {
      _id: userData?._id,
    };
    // setIsLoading(true)
    dispatch(
      getUserProfile({
        data: bodyParms,
        callBack: (err, res) => {
          // setIsLoading(false)
          if (err) return;
          if (res.status === "success") {
            setGameEnergyLeft(res?.data?.energyBalance);
            setBtncBalance(res?.data?.btncBalance);
          }
        },
      })
    );
  };


  const onCompleteEnergyBuy = (data) => {
    if (!data) return;
    setGameEnergyLeft(data?.energyBalance);
    setBtncBalance(data?.btncBalance);
    setBuy(false);
    getProfile();
  };

  // const onCompleteBtncBuy = (data) => {
  //   if (!data) return;
  //   setBtncBalance(data?.btncBalance);
  //   setBtncPop(false);
  // };

  const logoutGame = () => {
    dispatch(userSessionLogout({
      callBack: (err, res) => {
      }
    }));
    document.cookie = "";
    setTimeout(() => {
      navigate("/login");
    }, 1000)
  };

  return (
    <>
      <HowToPlayPop playInst={playInst} setPlayInst={setPlayInst} />
      {buy &&
        <BuyPop buy={buy} setBuy={setBuy} onFinish={onCompleteEnergyBuy} />}
      {/* {btncPop && (
        <BtncBalancePop
          pop={btncPop}
          setPop={setBtncPop}
          onFinish={onCompleteBtncBuy}
        />
      )} */}

      <header
        className={`${styles.siteHeader} siteHeader position-absolute w-100`}
        style={{ zIndex: 999, top: 0, left: 0, }}
      >
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div
                className={`${styles.header} box rounded p-2 px-4 d-flex align-items-center justify-content-between gap-10`}
              >
                <div
                  className={`${styles.innerBox} d-flex align-items-center justify-content-between p-1 px-2 rounded position-relative`}
                >
                  <span
                    className="icn position-absolute"
                    style={{
                      left: -10,
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <img
                      src={current}
                      alt=""
                      className="img-fluid object-fit-contain"
                      style={{ height: 40 }}
                    />
                  </span>
                  <span className="" style={{ width: 20 }}></span>
                  <span className="text-white fw-bold">
                    {gameEnergyLeft}
                    {/* {gameEnergyLeft} / {settings?.energy?.maxEnergyBalance} */}
                  </span>
                  <Button
                    onClick={handledBuy}
                    className="d-flex align-items-center justify-content-center border-0 p-0 ms-1"
                    variant="transparent"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="27"
                      viewBox="0 0 28 27"
                      fill="none"
                    >
                      <g opacity="0.2">
                        <path
                          d="M20.5124 0.825439H6.89693C3.29214 0.825439 0.369873 3.60231 0.369873 7.02775V20.6358C0.369873 24.0612 3.29214 26.8381 6.89693 26.8381H20.5124C24.1172 26.8381 27.0394 24.0612 27.0394 20.6358V7.02775C27.0394 3.60231 24.1172 0.825439 20.5124 0.825439Z"
                          fill="black"
                        />
                      </g>
                      <path
                        d="M22.7176 0.161133H4.67657C2.29387 0.161133 0.362305 1.99659 0.362305 4.26074V22.0742C0.362305 24.3383 2.29387 26.1738 4.67657 26.1738H22.7176C25.1003 26.1738 27.0318 24.3383 27.0318 22.0742V4.26074C27.0318 1.99659 25.1003 0.161133 22.7176 0.161133Z"
                        fill="url(#paint0_linear_0_4232)"
                      />
                      <path
                        d="M22.7176 0.161133H4.67657C2.29387 0.161133 0.362305 1.99659 0.362305 4.26074V19.4028C0.362305 21.667 2.29387 23.5024 4.67657 23.5024H22.7176C25.1003 23.5024 27.0318 21.667 27.0318 19.4028V4.26074C27.0318 1.99659 25.1003 0.161133 22.7176 0.161133Z"
                        fill="url(#paint1_linear_0_4232)"
                      />
                      <path
                        d="M19.6653 10.4978H15.8056V6.83017C15.8056 6.29939 15.5837 5.79034 15.1888 5.41502C14.7938 5.03971 14.2581 4.82886 13.6995 4.82886C13.1409 4.82886 12.6053 5.03971 12.2103 5.41502C11.8153 5.79034 11.5934 6.29939 11.5934 6.83017V10.4978H7.73379C7.17522 10.4978 6.63952 10.7086 6.24455 11.0839C5.84958 11.4593 5.62769 11.9683 5.62769 12.4991C5.62769 13.0299 5.84958 13.5389 6.24455 13.9142C6.63952 14.2895 7.17522 14.5004 7.73379 14.5004H11.5934V18.168C11.5934 18.6988 11.8153 19.2078 12.2103 19.5831C12.6053 19.9585 13.1409 20.1693 13.6995 20.1693C14.2581 20.1693 14.7938 19.9585 15.1888 19.5831C15.5837 19.2078 15.8056 18.6988 15.8056 18.168V14.5004H19.6653C20.2238 14.5004 20.7595 14.2895 21.1545 13.9142C21.5495 13.5389 21.7714 13.0299 21.7714 12.4991C21.7714 11.9683 21.5495 11.4593 21.1545 11.0839C20.7595 10.7086 20.2238 10.4978 19.6653 10.4978Z"
                        fill="#033556"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_0_4232"
                          x1="13.6971"
                          y1="0.161133"
                          x2="13.6971"
                          y2="26.1738"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#00EBFB" />
                          <stop offset="1" stop-color="#0289F4" />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_0_4232"
                          x1="13.6971"
                          y1="0.161133"
                          x2="13.6971"
                          y2="23.5024"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#00EBFB" />
                          <stop offset="1" stop-color="#0289F4" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </Button>
                </div>

                <div className="d-flex align-items-center gap-10 bg-transparent">
                  <div
                    className={`${styles.innerBox} d-flex align-items-center justify-content-end p-1 px-2 rounded position-relative me-2`}
                  >
                    <span
                      className="icn position-absolute"
                      style={{
                        left: -10,
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      <img
                        src={diamond}
                        alt=""
                        className="img-fluid object-fit-contain"
                        style={{ height: 45 }}
                      />
                    </span>
                    <span className="" style={{ width: 40 }}></span>
                    <span
                      className=" fw-bold text-end"
                      style={{ minWidth: 40, color: "#4FFFDC" }}
                    >
                      {btncBalance ? btncBalance : 0}
                    </span>
                    {/* <Button
                                            onClick={() => setBtncPop(true)}
                                            className="d-flex align-items-center justify-content-center border-0 p-0 ms-1"
                                            variant="transparent"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="28"
                                                height="27"
                                                viewBox="0 0 28 27"
                                                fill="none"
                                            >
                                                <g opacity="0.2">
                                                    <path
                                                        d="M20.5124 0.825439H6.89693C3.29214 0.825439 0.369873 3.60231 0.369873 7.02775V20.6358C0.369873 24.0612 3.29214 26.8381 6.89693 26.8381H20.5124C24.1172 26.8381 27.0394 24.0612 27.0394 20.6358V7.02775C27.0394 3.60231 24.1172 0.825439 20.5124 0.825439Z"
                                                        fill="black"
                                                    />
                                                </g>
                                                <path
                                                    d="M22.7176 0.161133H4.67657C2.29387 0.161133 0.362305 1.99659 0.362305 4.26074V22.0742C0.362305 24.3383 2.29387 26.1738 4.67657 26.1738H22.7176C25.1003 26.1738 27.0318 24.3383 27.0318 22.0742V4.26074C27.0318 1.99659 25.1003 0.161133 22.7176 0.161133Z"
                                                    fill="url(#paint0_linear_0_4232)"
                                                />
                                                <path
                                                    d="M22.7176 0.161133H4.67657C2.29387 0.161133 0.362305 1.99659 0.362305 4.26074V19.4028C0.362305 21.667 2.29387 23.5024 4.67657 23.5024H22.7176C25.1003 23.5024 27.0318 21.667 27.0318 19.4028V4.26074C27.0318 1.99659 25.1003 0.161133 22.7176 0.161133Z"
                                                    fill="url(#paint1_linear_0_4232)"
                                                />
                                                <path
                                                    d="M19.6653 10.4978H15.8056V6.83017C15.8056 6.29939 15.5837 5.79034 15.1888 5.41502C14.7938 5.03971 14.2581 4.82886 13.6995 4.82886C13.1409 4.82886 12.6053 5.03971 12.2103 5.41502C11.8153 5.79034 11.5934 6.29939 11.5934 6.83017V10.4978H7.73379C7.17522 10.4978 6.63952 10.7086 6.24455 11.0839C5.84958 11.4593 5.62769 11.9683 5.62769 12.4991C5.62769 13.0299 5.84958 13.5389 6.24455 13.9142C6.63952 14.2895 7.17522 14.5004 7.73379 14.5004H11.5934V18.168C11.5934 18.6988 11.8153 19.2078 12.2103 19.5831C12.6053 19.9585 13.1409 20.1693 13.6995 20.1693C14.2581 20.1693 14.7938 19.9585 15.1888 19.5831C15.5837 19.2078 15.8056 18.6988 15.8056 18.168V14.5004H19.6653C20.2238 14.5004 20.7595 14.2895 21.1545 13.9142C21.5495 13.5389 21.7714 13.0299 21.7714 12.4991C21.7714 11.9683 21.5495 11.4593 21.1545 11.0839C20.7595 10.7086 20.2238 10.4978 19.6653 10.4978Z"
                                                    fill="#033556"
                                                />
                                                <defs>
                                                    <linearGradient
                                                        id="paint0_linear_0_4232"
                                                        x1="13.6971"
                                                        y1="0.161133"
                                                        x2="13.6971"
                                                        y2="26.1738"
                                                        gradientUnits="userSpaceOnUse"
                                                    >
                                                        <stop stop-color="#00EBFB" />
                                                        <stop offset="1" stop-color="#0289F4" />
                                                    </linearGradient>
                                                    <linearGradient
                                                        id="paint1_linear_0_4232"
                                                        x1="13.6971"
                                                        y1="0.161133"
                                                        x2="13.6971"
                                                        y2="23.5024"
                                                        gradientUnits="userSpaceOnUse"
                                                    >
                                                        <stop stop-color="#00EBFB" />
                                                        <stop offset="1" stop-color="#0289F4" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </Button> */}
                  </div>
                  <Dropdown show={dropdownOpen} onToggle={handleToggle}>
                    <Dropdown.Toggle
                      variant="transparent"
                      className="border-0 p-0 noAfter bg-transparent"
                      id="dropdown-basic"

                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="17"
                        viewBox="0 0 26 17"
                        fill="none"
                      >
                        <rect
                          x="0.952637"
                          y="0.401611"
                          width="24.7717"
                          height="2.85827"
                          rx="1.42913"
                          fill="#1EC9F2"
                        />
                        <rect
                          x="0.952637"
                          y="7.0708"
                          width="24.7717"
                          height="2.85827"
                          rx="1.42913"
                          fill="#1EC9F2"
                        />
                        <rect
                          x="0.952637"
                          y="13.74"
                          width="24.7717"
                          height="2.85827"
                          rx="1.42913"
                          fill="#1EC9F2"
                        />
                      </svg>
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      className={`${styles.DropdownMenu} mt-3 p-0 py-3`}
                    >
                      {/* <Link
                                                to={"/leaderboard"}
                                                // onClick={handleHistory}
                                                className={`${styles.item} d-flex align-items-center text-white`}
                                                style={{ fontSize: 13 }}
                                            // href="#/action-1"
                                            >
                                                <span className="icn me-2">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="12"
                                                        height="12"
                                                        viewBox="0 0 32 32"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M20.5588 29.606H21.9265V22.9772H28.7647C29.2683 22.9772 29.6765 23.4012 29.6765 23.9242V29.606H31.0441C31.2959 29.606 31.5 29.818 31.5 30.0795V31.0265C31.5 31.288 31.2959 31.5 31.0441 31.5H0.955882C0.704105 31.5 0.5 31.288 0.5 31.0265V30.0795C0.5 29.818 0.704105 29.606 0.955882 29.606H2.32353V21.5567C2.32353 21.0337 2.73174 20.6098 3.23529 20.6098H10.0735V29.606H11.4412V17.2953C11.4412 16.7723 11.8494 16.3484 12.3529 16.3484H19.6471C20.1506 16.3484 20.5588 16.7723 20.5588 17.2953V29.606ZM13.8804 11.2366C12.9868 11.7245 12.0616 11.017 12.2309 9.99184L12.6357 7.54046L10.9209 5.80438C10.1979 5.07247 10.5599 3.93996 11.5509 3.79039L13.9207 3.43273L14.9806 1.20239C15.4274 0.262104 16.5762 0.269643 17.0194 1.20239L18.0793 3.43273L20.4491 3.79039C21.4481 3.94117 21.7963 5.07833 21.0791 5.80438L19.3643 7.54046L19.7691 9.99184C19.9398 11.0253 19.0061 11.7206 18.1196 11.2366L16 10.0792L13.8804 11.2366Z"
                                                            fill="#fff"
                                                        />
                                                    </svg>
                                                </span>
                                                Leaderboard
                                            </Link> */}
                      <span
                        onClick={() => {
                          navigate("/game-history")
                          handleToggle()
                        }} style={{ fontSize: 13 }} className={`${styles.item} d-flex align-items-center text-white`}>
                        {/* <Link
                        to={"/game-history"}
                        // onClick={handleHistory}
                        className={`${styles.item} d-flex align-items-center text-white`}
                        style={{ fontSize: 13 }}
                      // href="#/action-1"
                      > */}
                        <span className="icn me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 13"
                            fill="none"
                          >
                            <path
                              d="M8.13154 0.778896C9.28809 1.2942 10.2546 2.20136 10.8815 3.35989C11.5082 4.5184 11.7604 5.86364 11.5988 7.18722C11.4373 8.51077 10.871 9.73883 9.98765 10.6812C9.10435 11.6236 7.95336 12.2276 6.7129 12.3998C5.47245 12.5721 4.21179 12.3028 3.12615 11.6337C2.04052 10.9647 1.19051 9.93324 0.707775 8.69908C0.556277 8.31175 0.727231 7.86795 1.08961 7.70788C1.452 7.54772 1.86858 7.73188 2.02008 8.11922C2.38213 9.0448 3.01963 9.81845 3.83386 10.3202C4.64808 10.822 5.59358 11.0239 6.52392 10.8948C7.45428 10.7656 8.31748 10.3126 8.97996 9.60583C9.64244 8.89908 10.0672 7.978 10.1883 6.98531C10.3096 5.99263 10.1204 4.98371 9.65028 4.11482C9.18017 3.24593 8.45533 2.56556 7.58792 2.17908C6.72052 1.7926 5.75903 1.72158 4.85238 1.97703C4.25916 2.14416 3.71109 2.44494 3.24289 2.85474L3.73856 3.43944C4.16443 3.9418 3.81052 4.74041 3.17766 4.70511L0.342864 4.54697L0.479853 1.52272C0.510436 0.847567 1.2909 0.552139 1.71678 1.0545L2.28603 1.72601C2.9216 1.15597 3.67109 0.738576 4.48417 0.509496C5.69306 0.168903 6.97504 0.263592 8.13154 0.778896ZM6.09435 3.34832C6.48667 3.37021 6.78933 3.72724 6.77037 4.14578L6.68161 6.10535L7.83566 7.46668C8.09964 7.77811 8.07791 8.25795 7.78711 8.53841C7.49631 8.81887 7.04653 8.79377 6.78252 8.48237L5.23247 6.6539L5.34967 4.06652C5.36862 3.64799 5.70203 3.32644 6.09435 3.34832Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                        History
                        {/* </Link> */}
                      </span>
                      <Dropdown.Item
                        onClick={handlePlayInst}
                        className={`${styles.item} d-flex align-items-center text-white`}
                        style={{ fontSize: 13 }}
                        href=""
                      >
                        <span className="icn me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 13 13"
                            fill="none"
                          >
                            <path
                              d="M6.41634 12C9.4077 12 11.8327 9.53757 11.8327 6.5C11.8327 3.46243 9.4077 1 6.41634 1C3.42498 1 1 3.46243 1 6.5C1 9.53757 3.42498 12 6.41634 12Z"
                              stroke="white"
                              stroke-width="1.3"
                            />
                            <path
                              d="M5.39844 4.78125C5.39844 4.21171 5.85314 3.75 6.414 3.75C6.97486 3.75 7.42956 4.21171 7.42956 4.78125C7.42956 5.15935 7.22916 5.48993 6.93039 5.66945C6.6729 5.82411 6.414 6.05874 6.414 6.3625V7.05"
                              stroke="white"
                              stroke-width="1.3"
                              stroke-linecap="round"
                            />
                            <path
                              d="M6.42054 9.24941C6.71968 9.24941 6.96217 9.00317 6.96217 8.69941C6.96217 8.39566 6.71968 8.14941 6.42054 8.14941C6.1214 8.14941 5.87891 8.39566 5.87891 8.69941C5.87891 9.00317 6.1214 9.24941 6.42054 9.24941Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                        How to play
                      </Dropdown.Item>

                      <Dropdown.Item
                        //   onClick={handlePlayInst}
                        className={`${styles.item} d-flex align-items-center justify-content-between text-white`}
                        style={{ fontSize: 13 }}
                        href=""
                      >
                        <div className="left d-flex align-items-center">
                          <span className="icn me-2">
                            {userData?.enabledSound ?
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                              >
                                <g clip-path="url(#clip0_0_4090)">
                                  <path
                                    d="M0.747198 9.07977H2.46202C2.59726 9.21454 5.71308 11.8426 5.71308 11.8426C5.90062 11.9564 6.05978 11.9894 6.19577 11.9766C6.47223 11.9504 6.6665 11.7183 6.72553 11.4735C6.72852 11.4495 6.72852 0.529943 6.72478 0.505984C6.6665 0.260398 6.47148 0.0282897 6.19577 0.00208391C6.05978 -0.0106446 5.90062 0.0222998 5.71308 0.136108C5.71308 0.136108 2.59726 2.86001 2.46202 2.99478H0.747198C0.549029 2.99478 0.358976 3.07367 0.218849 3.21408C0.0787224 3.3545 0 3.54494 0 3.74352L0 8.33103C0 8.52961 0.0787224 8.72005 0.218849 8.86047C0.358976 9.00088 0.549029 9.07977 0.747198 9.07977Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M9.6942 2L9.12847 2.55805C9.98118 3.43702 10.4571 4.60668 10.4571 5.82327C10.4571 7.03986 9.98118 8.20952 9.12847 9.08848L9.6942 9.64654C10.6944 8.62121 11.2531 7.2536 11.2531 5.83069C11.2531 4.40778 10.6944 3.04017 9.6942 2.01484V2ZM8.56574 3.11611L8 3.68604C8.54029 4.26788 8.83998 5.02829 8.83998 5.81733C8.83998 6.60637 8.54029 7.36678 8 7.94863L8.56574 8.51856C9.25783 7.78473 9.64256 6.81962 9.64256 5.81733C9.64256 4.81505 9.25783 3.84994 8.56574 3.11611Z"
                                    fill="white"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_0_4090">
                                    <rect width="12" height="12" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 15 13" fill="none">
                                <g clip-path="url(#clip0_22_4625)">
                                  <mask id="mask0_22_4625" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="2" y="0" width="13" height="12">
                                    <path d="M14.0007 0H2.00073V12H14.0007V0Z" fill="white" />
                                  </mask>
                                  <g mask="url(#mask0_22_4625)">
                                    <path d="M2.74793 9.07977H4.46275C4.59799 9.21454 7.71381 11.8426 7.71381 11.8426C7.90135 11.9564 8.06051 11.9894 8.1965 11.9766C8.47296 11.9504 8.66723 11.7183 8.72626 11.4735C8.72925 11.4495 8.72925 0.529943 8.72551 0.505984C8.66723 0.260398 8.47221 0.0282897 8.1965 0.00208391C8.06051 -0.0106446 7.90135 0.0222998 7.71381 0.136108C7.71381 0.136108 4.59799 2.86001 4.46275 2.99478H2.74793C2.54976 2.99478 2.35971 3.07367 2.21958 3.21408C2.07945 3.3545 2.00073 3.54494 2.00073 3.74352V8.33103C2.00073 8.52961 2.07945 8.72005 2.21958 8.86047C2.35971 9.00088 2.54976 9.07977 2.74793 9.07977Z" fill="white" />
                                    <path d="M11.6949 2L11.1292 2.55805C11.9819 3.43702 12.4578 4.60668 12.4578 5.82327C12.4578 7.03986 11.9819 8.20952 11.1292 9.08848L11.6949 9.64654C12.6951 8.62121 13.2538 7.2536 13.2538 5.83069C13.2538 4.40778 12.6951 3.04017 11.6949 2.01484V2ZM10.5665 3.11611L10.0007 3.68604C10.541 4.26788 10.8407 5.02829 10.8407 5.81733C10.8407 6.60637 10.541 7.36678 10.0007 7.94863L10.5665 8.51856C11.2586 7.78473 11.6433 6.81962 11.6433 5.81733C11.6433 4.81505 11.2586 3.84994 10.5665 3.11611Z" fill="white" />
                                  </g>
                                </g>
                                <path d="M13.7974 0.788575L0.498944 12.6141" stroke="white" />
                                <defs>
                                  <clipPath id="clip0_22_4625">
                                    <rect width="12" height="12" fill="white" transform="translate(2.00073)" />
                                  </clipPath>
                                </defs>
                              </svg>}

                          </span>
                          Sound
                        </div>
                        <Switch
                          // offHandleColor={""}
                          // onHandleColor={"#36AEF2"}
                          offColor="#40405F"
                          onColor="#36AEF2"
                          uncheckedIcon=""
                          checkedIcon=""
                          // handleDiameter="1.5px"
                          height={24}
                          width={38}
                          onChange={(e) => manageSound(e)}
                          checked={userData?.enabledSound}
                          value={userData?.enabledSound ? true : false}
                        />
                      </Dropdown.Item>
                      {!isMobile() ?
                        <Dropdown.Item
                          onClick={() => logoutGame()}
                          className={`${styles.item} d-flex align-items-center text-white`}
                          style={{ fontSize: 13 }}
                          href=""
                        >
                          <span className="icn me-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 13 13"
                              fill="none"
                            >
                              <path
                                d="M6.41634 12C9.4077 12 11.8327 9.53757 11.8327 6.5C11.8327 3.46243 9.4077 1 6.41634 1C3.42498 1 1 3.46243 1 6.5C1 9.53757 3.42498 12 6.41634 12Z"
                                stroke="white"
                                stroke-width="1.3"
                              />
                              <path
                                d="M5.39844 4.78125C5.39844 4.21171 5.85314 3.75 6.414 3.75C6.97486 3.75 7.42956 4.21171 7.42956 4.78125C7.42956 5.15935 7.22916 5.48993 6.93039 5.66945C6.6729 5.82411 6.414 6.05874 6.414 6.3625V7.05"
                                stroke="white"
                                stroke-width="1.3"
                                stroke-linecap="round"
                              />
                              <path
                                d="M6.42054 9.24941C6.71968 9.24941 6.96217 9.00317 6.96217 8.69941C6.96217 8.39566 6.71968 8.14941 6.42054 8.14941C6.1214 8.14941 5.87891 8.39566 5.87891 8.69941C5.87891 9.00317 6.1214 9.24941 6.42054 9.24941Z"
                                fill="white"
                              />
                            </svg>
                          </span>
                          Logout
                        </Dropdown.Item>
                        : null}
                      <span
                        className="dropIcn position-absolute"
                        style={{
                          right: 16,
                          top: 6,
                          transform: "translateY(-100%)",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="36"
                          height="15"
                          viewBox="0 0 36 15"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_6_2630)">
                            <path
                              d="M15.0315 1.33346C16.9684 -0.528 20.03 -0.526275 21.9648 1.33737L38 16.7829C41.2608 16.7878 46.6942 19.246 49 21.8593C51.3058 24.4727 52.9957 28.3164 53 32.0122V150.059C52.9957 153.755 51.6984 157.298 49.3926 159.911C47.0869 162.525 43.9608 163.995 40.7 164H-98.7C-101.961 163.995 -105.087 162.525 -107.393 159.911C-109.698 157.298 -110.996 153.755 -111 150.059V29.2397C-110.996 25.5439 -109.698 22.0009 -107.393 19.3876C-105.087 16.7742 -101.961 15.3039 -98.7 15.299H0.5L15.0315 1.33346Z"
                              fill="#1D1D2A"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_6_2630">
                              <rect width="36" height="15" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {/* <Button
                  onClick={() => logoutGame()}
                  className={`${styles.bgBtn} d-flex align-items-center justify-content-center`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                  >
                    <path
                      d="M0 2.375C0 1.74511 0.231787 1.14102 0.644372 0.695621C1.05696 0.250222 1.61654 0 2.20002 0H12.1001C12.6836 0 13.2432 0.250222 13.6558 0.695621C14.0684 1.14102 14.3002 1.74511 14.3002 2.375V4.75C14.3002 5.06494 14.1843 5.36699 13.978 5.58969C13.7717 5.81239 13.4919 5.9375 13.2001 5.9375C12.9084 5.9375 12.6286 5.81239 12.4223 5.58969C12.216 5.36699 12.1001 5.06494 12.1001 4.75V2.375H2.20002V16.625H12.1001V14.25C12.1001 13.9351 12.216 13.633 12.4223 13.4103C12.6286 13.1876 12.9084 13.0625 13.2001 13.0625C13.4919 13.0625 13.7717 13.1876 13.978 13.4103C14.1843 13.633 14.3002 13.9351 14.3002 14.25V16.625C14.3002 17.2549 14.0684 17.859 13.6558 18.3044C13.2432 18.7498 12.6836 19 12.1001 19H2.20002C1.61654 19 1.05696 18.7498 0.644372 18.3044C0.231787 17.859 0 17.2549 0 16.625V2.375ZM16.8225 5.09794C17.0288 4.87532 17.3085 4.75025 17.6002 4.75025C17.8919 4.75025 18.1716 4.87532 18.3779 5.09794L21.6779 8.66044C21.8842 8.88313 22 9.18512 22 9.5C22 9.81488 21.8842 10.1169 21.6779 10.3396L18.3779 13.9021C18.1704 14.1184 17.8926 14.2381 17.6041 14.2354C17.3157 14.2327 17.0398 14.1078 16.8359 13.8876C16.6319 13.6674 16.5162 13.3696 16.5137 13.0582C16.5112 12.7469 16.6221 12.4469 16.8225 12.2229L18.2448 10.6875H7.70008C7.40834 10.6875 7.12855 10.5624 6.92226 10.3397C6.71596 10.117 6.60007 9.81494 6.60007 9.5C6.60007 9.18506 6.71596 8.88301 6.92226 8.66031C7.12855 8.43761 7.40834 8.3125 7.70008 8.3125H18.2448L16.8225 6.77706C16.6163 6.55437 16.5004 6.25238 16.5004 5.9375C16.5004 5.62262 16.6163 5.32063 16.8225 5.09794Z"
                      fill="#31B8FF"
                    />
                  </svg>
                </Button> */}
              </div>
            </Col>
          </Row>
        </Container>
      </header>
    </>
  );
};

export default Header;
