"use client";
/* Core */
import { createSlice } from "@reduxjs/toolkit";
import { activeGameDetail, buyEnergy, gameComplete, gameHistory, gameClaimHistoryApi, gameLeaderBoard, gameStart, updateGameApi, updateGameMove, claimBtncApi, getBitanicaBtncBalanceApi, transferFromBitanicaApi, claimBalanceApi, refferalsListApi, earningsListApi } from "./actions";


const initialState = {
  loading: false,
  gameDetail: null,
  gameHistoryList: [],
  totalGameHistory: 0,
  gameLeaderboardList: [],
  totalGameLeaderboard: 0,
  butEnergyLoading: false,
  loadMoreLoading: false,
  gameHistoryDetails: null,
  claimHistoryList: [],
  totalClaimHisotory: 0,
  loadingClaimHistory: false,
  userBitanicaBtncBalance: 0,
  binaticaLoading: false,
  btncAddLoading: false,
  claimLoading: false,
  refferalsList: [],
  totalRefferalsList: 0,
  loadingRefferalList: false,
  loadingEarningsList: false,
  earningsList: [],
  totalEarningsList: 0
};

export const gameSlice = createSlice({
  name: "gameSlice",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGameHistoryDetails(state, action) {
      const { data } = action.payload;
      state.gameHistoryDetails = data;
    },
    clearClaimHistoryList(state, action) {
      state.claimHistoryList = [];
      state.totalClaimHisotory = 0;
    },
    clearRefferalsList(state, action) {
      state.refferalsList = [];
      state.totalRefferalsList = 0;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(gameStart.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(gameStart.fulfilled, (state, action) => {
        state.loading = false;
        state.gameDetail = action.payload.data
      })
      .addCase(gameStart.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(gameComplete.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(gameComplete.fulfilled, (state, action) => {
        state.loading = false;
        state.user = {
          ...state.user,
          energyBalance: action.payload.data.energyBalance,
          btncBalance: action.payload.data.btncBalance
        }
      })
      .addCase(gameComplete.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateGameApi.pending, (state, action) => {
        // state.loading = true;
      })
      .addCase(updateGameApi.fulfilled, (state, action) => {
        // state.loading = false;
        state.gameDetail = action.payload.data
      })
      .addCase(updateGameApi.rejected, (state, action) => {
        // state.loading = false;
      })
      .addCase(activeGameDetail.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(activeGameDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.gameDetail = action.payload.data
      })
      .addCase(activeGameDetail.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(buyEnergy.pending, (state, action) => {
        state.butEnergyLoading = true;
      })
      .addCase(buyEnergy.fulfilled, (state, action) => {
        state.butEnergyLoading = false;
        state.user = {
          ...state.user,
          energyBalance: action.payload.data.energyBalance,
          btncBalance: action.payload.data.btncBalance
        }
      })
      .addCase(buyEnergy.rejected, (state, action) => {
        state.butEnergyLoading = false;
      })
      .addCase(gameHistory.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(gameHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.gameHistoryList =
          action.payload?.filter?.page === 1
            ? action.payload.data
            : [...state.gameHistoryList, ...action.payload.data];
        state.totalGameHistory = action.payload.totalcount;
      })
      .addCase(gameHistory.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(gameLeaderBoard.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(gameLeaderBoard.fulfilled, (state, action) => {
        state.loading = false;
        state.gameLeaderboardList = action.payload.data
        state.totalGameLeaderboard = action.payload.totalcount
      })
      .addCase(gameLeaderBoard.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(gameClaimHistoryApi.pending, (state, action) => {
        state.loadingClaimHistory = true;
      })
      .addCase(gameClaimHistoryApi.fulfilled, (state, action) => {
        state.loadingClaimHistory = false;
        state.claimHistoryList =
          action.payload?.filter?.page === 1
            ? action.payload.data
            : [...state.claimHistoryList, ...action.payload.data];
        state.totalClaimHisotory = action.payload.totalcount;
      })
      .addCase(gameClaimHistoryApi.rejected, (state, action) => {
        state.loadingClaimHistory = false;
      })
      .addCase(claimBalanceApi.pending, (state, action) => {
        state.claimLoading = true;
      })
      .addCase(claimBalanceApi.fulfilled, (state, action) => {
        state.claimLoading = false;
        state.claimHistoryList = state.claimHistoryList.map((item) => item?._id === action.payload?.data?._id ? { ...item, status: "processing" } : item)
      })
      .addCase(claimBalanceApi.rejected, (state, action) => {
        state.claimLoading = false;
      })
      .addCase(getBitanicaBtncBalanceApi.pending, (state, action) => {
        state.binaticaLoading = true;
      })
      .addCase(getBitanicaBtncBalanceApi.fulfilled, (state, action) => {
        state.binaticaLoading = false;
        state.userBitanicaBtncBalance = action.payload?.data?.balance
      })
      .addCase(getBitanicaBtncBalanceApi.rejected, (state, action) => {
        state.binaticaLoading = false;
      })
      .addCase(transferFromBitanicaApi.pending, (state, action) => {
        state.btncAddLoading = true;
      })
      .addCase(transferFromBitanicaApi.fulfilled, (state, action) => {
        state.btncAddLoading = false;
      })
      .addCase(transferFromBitanicaApi.rejected, (state, action) => {
        state.btncAddLoading = false;
      })
      .addCase(refferalsListApi.pending, (state, action) => {
        state.loadingRefferalList = true;
      })
      .addCase(refferalsListApi.fulfilled, (state, action) => {
        state.loadingRefferalList = false;
        state.refferalsList =
          action.payload?.filter?.page === 1
            ? action.payload.data
            : [...state.refferalsList, ...action.payload.data];
        state.totalRefferalsList = action.payload.totalcount;
      })
      .addCase(refferalsListApi.rejected, (state, action) => {
        state.loadingRefferalList = false;
      })
      .addCase(earningsListApi.pending, (state, action) => {
        state.loadingEarningsList = true;
      })
      .addCase(earningsListApi.fulfilled, (state, action) => {
        state.loadingEarningsList = false;
        state.earningsList =
          action.payload?.filter?.page === 1
            ? action.payload.data
            : [...state.earningsList, ...action.payload.data];
        state.totalEarningsList = action.payload.totalcount;
      })
      .addCase(earningsListApi.rejected, (state, action) => {
        state.loadingEarningsList = false;
      })
  }
});

export default gameSlice.reducer;