import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux";
import styles from "./tasklist.module.scss"
import InfiniteScroll from "react-infinite-scroll-component";
import LoaderCstm from '../../../components/Loader';
// img
import btnc from "../../../assets/images/diamond.png"
import TaskPop from '../../../components/Modals/TaskPopup'
import { checkBitanicaLinkApi, getUserProfile, tasksListApi } from '../../../lib/store/actions';
import { wait } from '../../../helper/common';
import { tasksSlice } from '../../../lib/store/tasks/slice';
// import useUserBalance from '../../../hooks/useUserBalance';
import { useGameContext } from '../../../context/GameContext';
import { useWindow } from '../../../hooks/useWindow';

const TaskList = () => {

    const { setGameEnergyLeft, setBtncBalance } = useGameContext();

    const { loading, tasksList, totalTasks, user, taskDetails } = useSelector(({ Tasks, Auth }) => ({
        user: Auth.user,
        loading: Tasks.loading,
        tasksList: Tasks.tasksList,
        totalTasks: Tasks.totalTasks,
        taskDetails: Tasks.taskDetails
    }));

    const dispatch = useDispatch();
    const [filter, setFilter] = useState({
        page: 1,
        limit: 100,
        order: 1,
        orderBy: "order",
        search: "",
        status: "",
    });

    const [taskPop, setTaskPop] = useState()

    const handleTask = (task) => {
        setTaskPop(!taskPop);
        dispatch((tasksSlice.actions.setTaskDetails({ data: task })))
    };


    useEffect(() => {

        dispatch(tasksListApi({ data: filter }));

    }, [filter]);

    const fetchMore = async () => {
        await wait(1000);
        setFilter((prev) => ({ ...prev, page: prev.page + 1 }));
    };


    const getProfile = () => {
        if (!user?._id) return;
        let bodyParms = {
            _id: user?._id,
        };
        // setIsLoading(true)
        dispatch(
            getUserProfile({
                data: bodyParms,
                callBack: (err, res) => {
                    // setIsLoading(false)
                    if (err) return;
                    if (res.status === "success") {
                        setGameEnergyLeft(res?.data?.energyBalance);
                        setBtncBalance(res?.data?.btncBalance);
                    }
                },
            })
        );
    };

    const onFinishTask = (data) => {
        dispatch(tasksListApi({ data: filter }));
        if (data) {
            getProfile();
            setTaskPop(false);
        }
    }

    // useEffect(() => {
    //     dispatch(checkBitanicaLinkApi({}));
    // }, []);

    const { screenSize: { dynamicHeight } } = useWindow();
    const elementRef = useRef(null); // Create a ref for the element
    const scrollStopOn = (dynamicHeight - 128) > elementRef.current?.offsetHeight ? true : false;

    return (
        <>
            {taskPop && <TaskPop taskPop={taskPop} setTaskPop={setTaskPop} onFinish={onFinishTask} />}
            <section className={`${styles.taskList} taskList position-relataive ${scrollStopOn && totalTasks <= 10 ? "no-minimize" : ""}`}>
                <Container>
                    <Row>
                        <Col lg="12" className='my-2 no-minimize'>

                            <div className="top text-center">
                                <h5 className="m-0 themeClr fw-bold">Task List</h5>
                            </div>
                        </Col>
                        <Col lg="12" className='my-2'>
                            {loading && filter.page === 1 && (
                                <LoaderCstm />
                            )}
                            {!loading && !tasksList?.length ?
                                <div className="d-flex justify-content-center align-items-center">
                                    <span className='text-white'>No record found!</span>
                                </div>
                                : null}
                            <InfiniteScroll
                                className='allow-scroll'
                                dataLength={tasksList?.length || 0}
                                next={fetchMore}
                                hasMore={tasksList?.length < totalTasks}
                                scrollThreshold={0}
                                scrollableTarget="taskListWrp"
                                loader={

                                    loading && filter.page >= 2 && (
                                        <div className="d-flex justify-content-center align-items-center">
                                            <Spinner className={styles.mSpinner} size="sm" />
                                        </div>
                                    )}


                            >
                                <ul ref={elementRef} id="taskListWrp" style={{
                                    maxHeight: "calc(100vh - 128px)",
                                    overflow: "scroll",
                                    height: "auto",
                                    // touchAction: "none",
                                    // PointerEvent: "none"
                                }} className="list-unstyled ps-0 mb-0 allow-scroll">
                                    {tasksList?.length ? tasksList?.map((task, key) => (
                                        <li className="py-1" key={key}>
                                            <div
                                                onClick={(
                                                    // e
                                                ) => {
                                                    // e?.preventDefault();
                                                    if (task?.isCompleted) return;
                                                    handleTask(task)
                                                }}
                                                className={`${styles.cardCstm} ${task?.isCompleted ? "opacity-50" : ""} ${task?._id === taskDetails?._id ? styles.opened : ""} px-3 py-2 cardCstm d-flex align-items-center gap-10 justify-content-between`}>
                                                <div className="left d-flex align-items-center gap-10">
                                                    <span className="flex-shrink-0 me-2">
                                                        <img src={task?.icon} alt="" className="img-fluid object-fit-contain" style={{ height: 42, width: 42 }} />
                                                    </span>
                                                    <div className="content">
                                                        <p className="m-0 text-white d-flex align-items-start">{task?.title} 
                                                        
                                                        {/* <span className="rounded-circle bg-danger" style={{height: 6, width: 6, top: 0, right: 0, background: "#ff0b0b" , zIndex: 9}}></span> */}
               
               </p>
                                                        <div className=" d-flex align-items-center gap-10">
                                                            <span className="icn flex-shrink-0">
                                                                <img src={task?.awardType === "coins" ? btnc : task?.awardData?.icon} alt="" className="img-fluid object-fit-contain" style={{ height: 25 }} />
                                                            </span>
                                                            <h6 className="m-0 fw-bold text-white">+{task?.awardType === "coins" ? task?.coins : task?.awardData?.quantity}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                {!task?.isCompleted ?
                                                    <div className="right">
                                                        <span className="icn">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="18" viewBox="0 0 11 18" fill="none">
                                                                <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M0.639805 17.6563C0.288106 17.3098 0.288352 16.7424 0.640351 16.3962L7.17004 9.97363C7.71412 9.43848 7.71412 8.56132 7.17004 8.02618L0.640193 1.60349C0.288281 1.25736 0.288035 0.690085 0.639647 0.343644C0.983933 0.00442192 1.53684 0.00461715 1.88089 0.344083L9.67237 8.03183C10.2146 8.56684 10.2144 9.44201 9.67188 9.97675L1.88103 17.6562C1.53686 17.9954 0.984052 17.9955 0.639805 17.6563Z" fill="white" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    :
                                                    <div className="right">
                                                        <span className="icn">
                                                            {check}
                                                        </span>
                                                    </div>
                                                }
                                            </div>
                                        </li>
                                    )) : null}
                                </ul>
                            </InfiniteScroll>

                        </Col>
                    </Row>
                </Container>
            </section >
        </>
    )
}

export default TaskList


const user = <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
    <g filter="url(#filter0_i_12_5567)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 21C0 9.40078 9.40078 0 21 0C32.5992 0 42 9.40078 42 21C42 32.5992 32.5992 42 21 42C9.40078 42 0 32.5992 0 21Z" fill="#4096EC" />
    </g>
    <path d="M7.65608 27.4313C7.74358 26.9281 8.0717 26.4688 8.53108 26.25C10.0623 25.4844 13.4092 24.3688 13.4092 24.3688V23.2531L13.3217 23.1875C12.6873 22.7062 12.228 21.9625 12.0967 21.1531L12.0748 21.0437H11.9873C11.4623 21.0437 11.0248 20.7156 10.8498 20.2781C10.7405 20.0813 10.6748 19.8625 10.6748 19.6219C10.6748 19.4688 10.6967 19.3156 10.7623 19.1625C10.7842 19.0094 10.8936 18.8562 11.003 18.7687L11.3092 18.5719L11.2436 18.2438C10.6748 15.8156 12.578 13.6063 15.1155 13.5625C15.1811 13.5625 15.2467 13.5625 15.2905 13.5625C15.3561 13.5625 15.4217 13.5625 15.4655 13.5625C18.0248 13.5844 19.9061 15.7937 19.3592 18.2219L19.2717 18.55L19.578 18.7469C19.7092 18.8344 19.7967 18.9875 19.8186 19.1406C19.8842 19.2937 19.9061 19.4469 19.9061 19.6C19.9061 19.8406 19.8405 20.0594 19.7311 20.2562C19.5561 20.7156 19.0967 21.0219 18.5936 21.0219H18.5061L18.4842 21.1312C18.353 21.9406 17.9155 22.6844 17.2592 23.1656L17.1717 23.2312V24.3469C17.1717 24.3469 20.5186 25.4625 22.0498 26.2281C22.5092 26.4688 22.8373 26.9063 22.9248 27.4094C23.1873 28.9406 23.2311 31.5219 23.2311 31.5219H7.32795C7.34983 31.5437 7.39358 28.9625 7.65608 27.4313Z" fill="white" />
    <path d="M22.5533 25.2656C21.6783 24.8281 20.3002 24.3031 19.2064 23.9094C19.3595 23.6031 19.622 23.3406 19.9283 23.1875C21.4595 22.4219 24.8064 21.3063 24.8064 21.3063V20.1906L24.7189 20.125C24.0845 19.6437 23.6252 18.9 23.4939 18.0906L23.472 17.9813H23.3845C22.8595 17.9813 22.422 17.6531 22.247 17.2156C22.1377 17.0188 22.072 16.8 22.072 16.5594C22.072 16.4063 22.0939 16.2531 22.1595 16.1C22.1814 15.9469 22.2908 15.7937 22.4002 15.7062L22.7064 15.5094L22.6408 15.1813C22.072 12.7531 23.9752 10.5438 26.5127 10.5C26.5783 10.5 26.6439 10.5 26.6877 10.5C26.7533 10.5 26.8189 10.5 26.8627 10.5C29.4002 10.5656 31.2814 12.7531 30.7345 15.1813L30.647 15.5094L30.9533 15.7062C31.0845 15.7937 31.172 15.9469 31.1939 16.1C31.2595 16.2531 31.2814 16.4063 31.2814 16.5594C31.2814 16.8 31.2158 17.0188 31.1064 17.2156C30.9314 17.675 30.472 17.9813 29.9689 17.9813H29.8814L29.8595 18.0906C29.7502 18.9 29.3127 19.6219 28.6564 20.125L28.5689 20.1906V21.3063C28.5689 21.3063 31.9158 22.4219 33.447 23.1875C33.9064 23.4281 34.2345 23.8656 34.322 24.3688C34.5845 25.9 34.6283 28.4812 34.6283 28.4812H24.172C24.1283 28.0437 24.0845 27.6281 24.0189 27.2562C23.8658 26.3812 23.3189 25.6594 22.5533 25.2656Z" fill="white" />
    <defs>
        <filter id="filter0_i_12_5567" x="0" y="0" width="42" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_12_5567" />
        </filter>
    </defs>
</svg>

const facebook = <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
    <g filter="url(#filter0_i_1_292)">
        <path d="M37.8 21.35C37.8 30.8217 30.2784 38.5 21 38.5C11.7216 38.5 4.2 30.8217 4.2 21.35C4.2 11.8783 11.7216 4.2 21 4.2C30.2784 4.2 37.8 11.8783 37.8 21.35Z" fill="white" />
        <path d="M21 0C9.40078 0 0 9.40078 0 21C0 32.5992 9.40078 42 21 42C32.5992 42 42 32.5992 42 21C42 9.40078 32.5992 0 21 0ZM25.6348 17.4398L25.3969 20.5816H22.173V31.4918H18.1043V20.5816H15.9305V17.4398H18.1043V15.3316C18.1043 14.4047 18.1289 12.9691 18.8016 12.075C19.5152 11.1316 20.4914 10.4918 22.173 10.4918C24.9129 10.4918 26.0613 10.8855 26.0613 10.8855L25.5199 14.1012C25.5199 14.1012 24.6176 13.8387 23.7727 13.8387C22.9277 13.8387 22.173 14.1422 22.173 14.9871V17.4398H25.6348Z" fill="#017BF7" />
    </g>
    <defs>
        <filter id="filter0_i_1_292" x="0" y="0" width="42" height="46" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1_292" />
        </filter>
    </defs>
</svg>

const check = <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
    <path d="M21 10.5C21 16.299 16.299 21 10.5 21C4.70101 21 0 16.299 0 10.5C0 4.70101 4.70101 0 10.5 0C16.299 0 21 4.70101 21 10.5Z" fill="url(#paint0_linear_1_469)" />
    <path d="M7.99881 13.5305L5.1604 10.552L4.19385 11.5591L7.99881 15.5519L16.1669 6.98064L15.2071 5.97351L7.99881 13.5305Z" fill="white" />
    <defs>
        <linearGradient id="paint0_linear_1_469" x1="10.5" y1="0" x2="10.5" y2="21" gradientUnits="userSpaceOnUse">
            <stop stop-color="#6EFFB9" />
            <stop offset="1" stop-color="#10BF60" />
        </linearGradient>
    </defs>
</svg>