import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { gameArray } from '../../helper/common';
import { useDispatch, useSelector } from 'react-redux';
import { getBitanicaBtncBalanceApi } from '../../lib/store/actions';
import { Howl, Howler } from 'howler';
// import startRound from "../../assets/sound/startround.mp3";
// import claim from "../../assets/sound/claim.mp3";
// import bombendgame from "../../assets/sound/bombendgame.mp3";
// import diamondevenline from "../../assets/sound/diamondevenline.mp3";
// import diamondoddline from "../../assets/sound/diamondoddline.mp3";

// Create the Game Context
const GameContext = createContext();

const sounds = {
    startround: "https://sound.bitanicagames.com/startround.mp3",
    claim: "https://sound.bitanicagames.com/claim.mp3",
    bombendgame: "https://sound.bitanicagames.com/bombendgame.mp3",
    diamondevenline: "https://sound.bitanicagames.com/diamondevenline.mp3",
    diamondoddline: "https://sound.bitanicagames.com/diamondoddline.mp3",
    line1: "https://sound.bitanicagames.com/Bitanica+Christms+Melody+01.wav",
    line2: "https://sound.bitanicagames.com/Bitanica+Christms+Melody+02.wav",
    line3: "https://sound.bitanicagames.com/Bitanica+Christms+Melody+03.wav",
    line4: "https://sound.bitanicagames.com/Bitanica+Christms+Melody+04.wav",
    line5: "https://sound.bitanicagames.com/Bitanica+Christms+Melody+05.wav",
    line6: "https://sound.bitanicagames.com/Bitanica+Christms+Melody+06.wav",
    line7: "https://sound.bitanicagames.com/Bitanica+Christms+Melody+07.wav",
    line8: "https://sound.bitanicagames.com/Bitanica+Christms+Melody+08.wav",
    line9: "https://sound.bitanicagames.com/Bitanica+Christms+Melody+09.wav",
    line10: "https://sound.bitanicagames.com/Bitanica+Christms+Melody+10.wav",
    line11: "https://sound.bitanicagames.com/Bitanica+Christms+Melody+11.wav",
    line12: "https://sound.bitanicagames.com/Bitanica+Christms+Melody+12.wav",
    line13: "https://sound.bitanicagames.com/Bitanica+Christms+Melody+13.wav",
    line14: "https://sound.bitanicagames.com/Bitanica+Christms+Melody+14.wav",
    line15: "https://sound.bitanicagames.com/Bitanica+Christms+Melody+15.wav",
};


// Preload sounds using Howler
const preloadSounds = (soundFiles, callback) => {
    let loadedSounds = {};
    let soundsToLoad = Object.keys(soundFiles).length;

    const retryLoad = (key, src, attempt = 1) => {
        if (attempt > 30) {
            console.error(`Failed to load sound "${key}" after 10 attempts.`);
            return;
        }

        const sound = new Howl({
            src: [src],
            preload: true,
            onload: () => {
                console.warn(`loaded sound "${key}" (${attempt})`);
                soundsToLoad -= 1;
                if (soundsToLoad === 0 && callback) callback();
            },
            onloaderror: () => {
                console.warn(`Retrying to load sound "${key}" (attempt ${attempt + 1})`);
                retryLoad(key, src, attempt + 1);
            },
        });

        loadedSounds[key] = sound; // Store the Howl instance
    };

    Object.entries(soundFiles).forEach(([key, src]) => retryLoad(key, src));
    return loadedSounds;
};

const afterLoadSound = preloadSounds(sounds);



// GameProvider Component
export const GameProvider = ({ children }) => {
    const [game, setGame] = useState({
        ...gameArray,
        gameData: gameArray.gameData.reverse(),
        lineData: gameArray.lineData.reverse(),
    });
    const [isGameStart, setGameStart] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [gameLoading, setGameLoading] = useState(true);
    const { user: userData } = useSelector(({ Auth }) => Auth);
    const [gameEnergyLeft, setGameEnergyLeft] = useState(userData?.energyBalance || 0);
    const [btncBalance, setBtncBalance] = useState(userData?.btncBalance || 0);
    const [audioLoad, setAudioLoad] = useState(false);
    const currentSoundRef = useRef(null);

    const dispatch = useDispatch();

    const resetGame = async () => {
        setGame({
            ...gameArray,
            gameData: gameArray.gameData.reverse(),
            lineData: gameArray.lineData.reverse(),
        });
    };

    useEffect(() => {
        if (userData) {
            setGameEnergyLeft(userData.energyBalance || 0);
            setBtncBalance(userData.btncBalance || 0);
        }
    }, [userData]);

    const SyncBitanicaBalance = async () => {
        dispatch(
            getBitanicaBtncBalanceApi({
                callBack: (err, data) => {
                    if (!err) setBtncBalance(data?.balance);
                },
            })
        );
    };


    const resumeAudioContext = async (retries = 5, delay = 500) => {
        for (let attempt = 1;attempt <= retries;attempt++) {
            if (Howler.ctx.state === 'suspended') {
                try {
                    await Howler.ctx.resume();
                    console.info('Audio context resumed.');
                    break;
                } catch (error) {
                    console.warn(`Audio context resume failed (attempt ${attempt}):`, error);
                }
            }
            if (attempt < retries) await new Promise((resolve) => setTimeout(resolve, delay));
        }

        if (Howler.ctx.state !== 'running') {
            console.error('Failed to resume audio context after retries.');
        }
    };

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                resumeAudioContext();
            }
        };

        const handleFocus = () => {
            resumeAudioContext();
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('focus', handleFocus);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('focus', handleFocus);
        };
    }, []);

    const playSound = (soundKey) => {

        if (userData?.enabledSound && afterLoadSound[soundKey]) {
            if (currentSoundRef.current) {
                currentSoundRef.current.stop(); // Stop the previous sound
            }

            afterLoadSound[soundKey].stop(); // Reset the sound
            afterLoadSound[soundKey].play(); // Play the sound

            // Update the reference to the new sound
            currentSoundRef.current = afterLoadSound[soundKey];
        }
    };

    return (
        <GameContext.Provider
            value={{
                gameLoading,
                setGameLoading,
                game,
                setGame,
                isGameStart,
                isLoading,
                setGameStart,
                setIsLoading,
                resetGame,
                gameEnergyLeft,
                setGameEnergyLeft,
                btncBalance,
                setBtncBalance,
                SyncBitanicaBalance,
                audioLoad,
                playSound,
            }}
        >
            {children}
        </GameContext.Provider>
    );
};

// Custom Hook to use the Game Context
export const useGameContext = () => {
    const context = useContext(GameContext);
    if (!context) {
        throw new Error('useGameContext must be used within a GameProvider');
    }
    return context;
};
